<template>
  <v-container
    class="pa-0 mt-1 d-flex align-start"
    :style="{ maxWidth: maxWidth + 'px' }"
  >
    <v-card
      tile
      class="rounded-lg"
      width="100%"
      :height="$vuetify.breakpoint.height - 100 + 'px'"
      :loading="loading"
    >
      <v-card-title class="primary white--text pa-1">
        <v-btn icon dark to="/">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <span class="mr-2">Networking Room</span>
      </v-card-title>
      <v-card-text class="pa-0 rounded-lg card-relative">
        <v-row no-gutters>
          <v-col cols="9">
            <v-card class="pa-2" flat tile>
              <v-card-subtitle class="pa-2">
                <v-row dense>
                  <!-- <v-col cols="1" align-self="center" class="text-right mr-1">
                    {{ $t("common.attendee") }}
                  </v-col> -->
                  <v-col cols="3" align-self="center" class="text-right">
                    <v-row dense>
                      <v-text-field
                        v-model.trim="search"
                        v-debounce:300ms="searchAttendee"
                        outlined
                        :placeholder="
                          $t('common.attendee') + ' ' + $t('common.name')
                        "
                        hide-details
                        dense
                        clearable
                        @click:clear="clearSearch()"
                        append-icon="mdi-magnify"
                        label="Search"
                      ></v-text-field>
                    </v-row>
                  </v-col>
                  <!-- <v-col cols="2" align-self="center" class="text-right mr-1">
                    {{ $t("profile.category") }}
                  </v-col> -->
                  <v-col cols="3" align-self="center" class="text-right">
                    <v-select
                      v-model="sponsor_type"
                      outlined
                      dense
                      :items="category_list"
                      label="User type"
                      hide-details
                      @change="searchAttendee"
                    ></v-select>
                  </v-col>
                  <v-col cols="6" class="text-right">
                    <v-btn-toggle mandatory>
                      <v-btn @click="is_list_view = true">
                        <v-icon>mdi-view-list</v-icon>
                      </v-btn>
                      <v-btn @click="is_list_view = false">
                        <v-icon>mdi-view-grid</v-icon>
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                </v-row>
              </v-card-subtitle>
              <v-card-text
                class="pa-4"
                style="overflow-y: scroll"
                :style="{ maxHeight: $vuetify.breakpoint.height - 235 + 'px' }"
              >
                <v-list v-if="is_list_view">
                  <div
                    v-for="(item, index) in attendee_list"
                    :key="item.attendee_id"
                  >
                    <v-list-item class="pa-0">
                      <v-badge
                        :color="item.status_color"
                        dot
                        bottom
                        bordered
                        offset-x="30"
                        offset-y="15"
                      >
                        <v-list-item-avatar size="40">
                          <v-img
                            :src="item.profile_pic"
                            :lazy-src="FRONT_ASSETS + 'placeholder.png'"
                          ></v-img>
                        </v-list-item-avatar>
                      </v-badge>
                      <v-list-item-content>
                        <v-list-item-title
                          class="name"
                          v-html="item.full_name"
                        ></v-list-item-title>
                        <v-list-item-subtitle class="mt-1">
                          {{ item.company_name
                          }}<span v-if="item.designation">
                            - {{ item.designation }}</span
                          >
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-row class="px-3">
                          <!-- <v-tooltip
                            bottom
                            v-if="
                              userData.attendee_id != item.attendee_id &&
                                item.online_status == 1 &&
                                item.call_status === 'available'
                            "
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                :to="`/call/video?joineeId=${item.attendee_id}`"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon color="primary">mdi-phone</v-icon>
                              </v-btn>
                            </template>
                            <span>Make Video Call</span>
                          </v-tooltip> -->
                          <v-btn
                            icon
                            target="_blank"
                            :href="item.linkedin_url"
                            v-if="item.linkedin_url"
                          >
                            <v-icon color="light-blue darken-4"
                              >mdi-linkedin</v-icon
                            >
                          </v-btn>
                          <v-btn
                            icon
                            @click="startChatWithAttendee(item)"
                            v-if="userData.attendee_id != item.attendee_id"
                          >
                            <v-icon color="grey darken-3"
                              >mdi-message-text-outline</v-icon
                            >
                          </v-btn>
                        </v-row>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider
                      v-if="index != attendee_list.length - 1"
                    ></v-divider>
                  </div>
                </v-list>
                <v-row v-if="!is_list_view">
                  <v-col
                    cols="4"
                    v-for="item in attendee_list"
                    :key="item.attendee_id"
                  >
                    <v-card outlined>
                      <v-list-item three-line>
                        <v-list-item-content>
                          <v-list-item-title class="headline mb-1 name">
                            {{ item.full_name }}
                          </v-list-item-title>
                          <v-list-item-subtitle class="mt-1">
                            {{ item.company_name }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            class="mt-1"
                            v-if="item.designation"
                          >
                            {{ item.designation }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle
                            class="mt-1"
                            v-if="item.online_status"
                          >
                            <span v-html="item.display_online_status"></span>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-avatar size="50">
                          <v-img
                            :src="item.profile_pic"
                            :lazy-src="FRONT_ASSETS + 'placeholder.png'"
                          ></v-img>
                        </v-list-item-avatar>
                      </v-list-item>
                      <v-card-actions>
                        <v-btn
                          icon
                          @click="startChatWithAttendee(item)"
                          v-if="userData.attendee_id != item.attendee_id"
                        >
                          <v-icon color="grey darken-3"
                            >mdi-message-text-outline</v-icon
                          >
                        </v-btn>
                        <v-btn
                          icon
                          target="_blank"
                          :href="item.linkedin_url"
                          v-if="item.linkedin_url"
                        >
                          <v-icon color="light-blue darken-4"
                            >mdi-linkedin</v-icon
                          >
                        </v-btn>
                        <!-- <v-tooltip
                          bottom
                          v-if="
                            userData.attendee_id != item.attendee_id &&
                              item.online_status == 1 &&
                              item.call_status === 'available'
                          "
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              :to="`/call/video?joineeId=${item.attendee_id}`"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon color="primary">mdi-phone</v-icon>
                            </v-btn>
                          </template>
                          <span>Make Video Call</span>
                        </v-tooltip> -->
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
                <div v-if="attendee_list.length == 0">
                  {{ $t("common.not_found") }}
                </div>
                <div
                  v-if="
                    total_attendees >= 25 &&
                      total_attendees > attendee_start_from
                  "
                  class="text-center"
                >
                  <v-btn
                    elevation="2"
                    small
                    class="text-capitalize mt-5"
                    @click="loadMore"
                    >{{ $t("common.load_more") }}
                    <v-icon right>mdi-arrow-down-circle</v-icon></v-btn
                  >
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3" class="pa-1">
            <div class="d-flex align-center" :style="{ height: '100%' }">
              <div class="d-flex flex-column self-center" style="width: 100%">
                <v-card
                  class="pa-2"
                  outlined
                  tile
                  @click="$helper.openUrl(object_list.ADVERTISE_1.link)"
                  :ripple="object_list.ADVERTISE_1.link != null"
                >
                  <v-img
                    :src="object_list.ADVERTISE_1.upload_url"
                    max-height="230"
                    contain
                  ></v-img>
                </v-card>
                <v-card
                  class="pa-2"
                  outlined
                  tile
                  @click="$helper.openUrl(object_list.ADVERTISE_2.link)"
                  :ripple="object_list.ADVERTISE_2.link != null"
                >
                  <v-img
                    :src="object_list.ADVERTISE_2.upload_url"
                    max-height="230"
                    contain
                  ></v-img>
                </v-card>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from "@/helper/axios";
import { mapState, mapMutations } from "vuex";
import { getCallStatusCode } from "../../helper/attendee";

export default {
  name: "SessionList",
  components: {},
  data() {
    return {
      delay: 3000,
      search: "",
      is_list_view: true,
      sponsor_type: "",
      total_attendees: 0,
      category_list: [
        { text: "All", value: "" },
        { text: "Attendees", value: 1 },
        { text: "Representative", value: 10 },
        { text: "Speaker", value: 12 },
      ],
      attendee_list: [],
      attendee_start_from: 0,
      object_list: {
        ADVERTISE_1: {},
        ADVERTISE_2: {},
      },
      loading: false,
      userData: [],
    };
  },
  computed: {
    ...mapState("utils", ["maxWidth", "pageBackgroundImage"]),
  },
  methods: {
    ...mapMutations("utils", ["setBackground"]),
    ...mapMutations("notifications", [
      "setExpandBox",
      "setTempPrivateMessage",
      "setChatPrivate",
      "setPrivateChatUser",
    ]),
    clearSearch() {
      this.search = "";
      this.attendee_start_from = 0;
      this.getVendorList();
    },
    startChatWithAttendee(attendee) {
      this.setChatPrivate(true);
      this.setTempPrivateMessage(attendee);
      this.setPrivateChatUser(attendee);
      this.setExpandBox(0);
    },
    searchAttendee() {
      this.attendee_start_from = 0;
      this.getVendorList();
    },
    getVendorList() {
      this.loading = "success";
      let _self = this;
      axios
        .get(
          "/attendee/list?search=" +
            _self.search +
            "&sponsor_type=" +
            _self.sponsor_type +
            "&start_from=" +
            _self.attendee_start_from
        )
        .then(function(response) {
          if (_self.attendee_start_from > 0) {
            _self.attendee_list.push(...response.data.data);
          } else {
            _self.attendee_list = response.data.data;
          }
          _self.total_attendees = response.data.totalRecord;
        })
        .catch(function(e) {
          console.log(e);
        })
        .then(() => {
          this.loading = false;
        });
    },
    getObjects() {
      let _self = this;
      axios
        .get("object/get?page_name=NETWORKING_ROOM")
        .then(function(response) {
          for (let key in response.data.data) {
            let data = response.data.data[key];
            _self.object_list[data.page_position[0].value] = data;
          }
        })
        .catch(function(e) {
          console.log(e);
        });
      _self.loading = false;
    },
    loadMore() {
      this.attendee_start_from = this.attendee_start_from + 25;
      this.getVendorList();
    },
    handleAttendeeStatusEvents(data) {
      if (data.attendee_id && Array.isArray(this.attendee_list)) {
        const attendeeIndex = this.attendee_list.findIndex((attendee) => {
          return attendee.attendee_id == Number(data.attendee_id);
        });

        if (attendeeIndex != -1) {
          const tempAttendees = [...this.attendee_list];
          tempAttendees[attendeeIndex].call_status = getCallStatusCode(
            data.call_status
          );
          tempAttendees[attendeeIndex].online_status = Number(
            data.online_status
          );
          tempAttendees[attendeeIndex].status_color =
            Number(data.online_status) == 1 ? "green" : "red";
          this.attendee_list = tempAttendees;
        }
      }
    },
    subscribeToChannels() {
      let subscription = this.$ws.socket.getSubscription("attendee_status");

      if (!subscription) {
        subscription = this.$ws.subscribe("attendee_status");

        this.$ws.$on("attendee_status", this.handleAttendeeStatusEvents);
      } else {
        this.$ws.$on("attendee_status", this.handleAttendeeStatusEvents);
      }
    },
    unsubscribeFromChannels() {
      this.$ws.$off("attendee_status", this.handleAttendeeStatusEvents);
    },
  },
  mounted() {
    this.subscribeToChannels();
    this.getVendorList();
    this.getObjects();
    this.setBackground({
      image:
        'url("' +
        process.env.VUE_APP_ASSETS +
        'networking_lounge_background.png")',
    });
    this.userData = JSON.parse(localStorage.getItem("pcm_user"));
  },
  beforeDestroy() {
    this.unsubscribeFromChannels();
  },
  destroyed() {
    this.setBackground({
      image: "",
    });
  },
};
</script>
<style></style>
